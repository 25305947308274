import { Editor } from '@tinymce/tinymce-react'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import toast, { Toast } from 'react-hot-toast'
import { Header } from '../../../components'
import { AdminPortal } from '../../../components/Header/HeaderList'
import { Loading } from '../../../components/UI'
import FormButton from '../../../components/UI/Form/Button'
import {
  MergeFieldList,
  mergeFieldRegex,
  NotificationSentSuccessMessage,
  NotificationWithPendingPlaceholdersMessage,
} from '../../../helper/constants'
import { MeasurementType, PageLinks } from '../../../helper/enum'
import { useStore } from '../../../services/stores/store'

const AdminSendNotificationPage = () => {
  const { alertLevelStore, notificationStore, commonStore } = useStore()
  const { selectedAlertLevel } = alertLevelStore
  const {
    subject,
    content,
    isEditorDisabled,
    isSending,
    processMergeFields,
    setAlertLevelId,
    setContent,
    setSubject,
    setIsEditorDisabled,
    setMergeField,
    sendNotification,
  } = notificationStore
  const [textContent, setTextContent] = useState('')

  const isContentClean = (): boolean => {
    const isContentClean = !textContent.match(mergeFieldRegex)
    return isContentClean
  }

  const openModal = () => {
    if (isContentClean()) {
      toast.custom((t) => createModal(t))
    } else {
      toast.error(NotificationWithPendingPlaceholdersMessage)
    }
  }

  const createModal = (t: Toast) => {
    return (
      <>
        <div
          className="h-full outline-none overflow-x-hidden overflow-y-auto"
          tabIndex={-1}
          role="dialog"
        >
          <div className="relative w-auto pointer-events-none">
            <div className="border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-black opacity-80 bg-clip-padding rounded-md outline-none text-current">
              <div className=" flex flex-shrink-0 items-center justify-between p-4 border-b-0 border-gray-200 rounded-t-md">
                <h5 className="text-xl font-medium leading-normal text-white">
                  Sure you want to send this?
                </h5>
                <button
                  type="button"
                  className="btn-close box-content w-4 h-4 p-1 !text-white border-none rounded-none self-start opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-white hover:opacity-75 hover:no-underline"
                  aria-label="Close"
                  onClick={() => toast.remove(t.id)}
                ></button>
              </div>
              <div className="relative p-4 text-white">
                Notification will be sent to Recipients. Would you like to
                proceed?
              </div>
              <div className="flex flex-shrink-0 flex-wrap items-center justify-center p-4 border-t-0 border-gray-200 rounded-b-md">
                <div className="flex justify-center space-x-4">
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-secondary text-white font-medium text-sm leading-tight uppercase rounded shadow-md hover:bg-opacity-90 hover:shadow-lg active:bg-opacity-80 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => {
                      sendAlertLevelNotification()
                      toast.remove(t.id)
                    }}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-red-500 text-white font-medium text-sm leading-tight uppercase rounded shadow-md hover:bg-opacity-90 hover:shadow-lg active:bg-opacity-80 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => toast.remove(t.id)}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const sendAlertLevelNotification = async () => {
    try {
      if (commonStore.user) {
        await sendNotification(commonStore.user.id)
        toast.success(NotificationSentSuccessMessage)
      }
    } catch (error) {
      console.log('Error sending notification')
    }
  }

  useEffect(() => {
    if (selectedAlertLevel) {
      const today = new Date()
      setAlertLevelId(selectedAlertLevel.id)

      setMergeField(
        MergeFieldList.userName.placeholder,
        commonStore.userName ?? ''
      )
      setMergeField(
        MergeFieldList.waterLevel.placeholder,
        selectedAlertLevel.level.toString()
      )
      setMergeField(
        MergeFieldList.currentDateTime.placeholder,
        `${today.toLocaleDateString()} ${today.toLocaleTimeString()}`
      )
      setMergeField(
        MergeFieldList.site.placeholder,
        selectedAlertLevel.measurementSite
      )

      setSubject(selectedAlertLevel.templateName)
      processMergeFields(selectedAlertLevel.emailTemplate)
    }
  }, [alertLevelStore])

  return (
    <div className="default-bg">
      <Header
        page={PageLinks.AdminCommunicate}
        header={AdminPortal}
        currentPageName="Communicate"
      />
      <div className="absolute w-screen mt-28">
        <h1 className="px-4 text-2xl">{selectedAlertLevel?.measurementSite}</h1>
        <h1 className="px-4 py-2 text-2xl">
          {selectedAlertLevel?.measurementSiteType ===
          MeasurementType.WaterLevel
            ? selectedAlertLevel.name
            : selectedAlertLevel?.measurementSiteType}
        </h1>
        <div className="w-full flex flex-col sm:flex-row flex-wrap sm:flex-nowrap py-16 flex-grow">
          <div className="w-[50%] flex-shrink flex-grow-0 px-4 border-r-2">
            <h2 className="font-semibold pb-2">Information</h2>
            <textarea
              title="Instructions"
              placeholder=""
              className="w-full h-[600px] resize-none p-4"
              value={selectedAlertLevel?.description ?? ''}
              disabled={true}
            />
          </div>
          <div className="w-full flex-grow px-4 border-x-2">
            <h2 className="font-semibold pb-2">Message to be sent</h2>
            <input
              title="Please provide a brief subject of the site (maximum 24 words and 300 characters)."
              placeholder="Subject"
              className="w-full mb-4 p-4 border-2 rounded disabled:!border-0 disabled:!rounded-none"
              value={subject}
              disabled={isEditorDisabled}
              onChange={(event) => {
                const input = event.target.value
                const words = input.split(' ')
                const charCount = input.length

                if (words.length > 24 || charCount > 300) {
                  event.preventDefault()
                  toast.error(
                    'Subject can have only 24 words and 300 characters'
                  )
                } else {
                  setSubject(input)
                }
              }}
              onPaste={(event) => {
                const clipboardText = event.clipboardData.getData('text')
                const words = clipboardText.split(' ')
                const charCount = clipboardText.length

                if (words.length > 24 || charCount > 300) {
                  event.preventDefault()
                  toast.error(
                    'Subject can have only 24 words and 300 characters'
                  )
                }
              }}
            />

            <Editor
              id="message-area"
              textareaName="MessageArea"
              tinymceScriptSrc={
                process.env.REACT_APP_PUBLIC_URL + '/tinymce/tinymce.min.js'
              }
              value={content}
              disabled={isEditorDisabled}
              init={{
                height: 525,
                menubar: false,
              }}
              onInit={(evt, editor) => {
                setTextContent(editor.getContent({ format: 'text' }))
              }}
              onEditorChange={(newMessage, editor) => {
                setContent(newMessage)
                setTextContent(editor.getContent({ format: 'text' }))
              }}
            />
            <FormButton
              className="float-right !w-32 !text-lg mt-2"
              onClick={() => {
                setIsEditorDisabled(!isEditorDisabled)
              }}
              type="button"
            >
              {isEditorDisabled ? 'Customize' : 'Done'}
            </FormButton>
          </div>
          <div className="w-[50%] flex-shrink flex-grow-0 px-4 border-l-2">
            <h2 className="font-semibold pb-2">
              Recipients: {selectedAlertLevel?.subscribedUsers.length}
            </h2>
            <ul className="overflow-auto h-[600px]">
              {selectedAlertLevel?.subscribedUsers.map((user, index) => {
                return (
                  <li
                    className="text-md"
                    key={index}
                  >{`${user.name}  (${user.email})`}</li>
                )
              })}
            </ul>
            <FormButton
              className="mt-2 float-right !w-24 !text-lg disabled:bg-red-400"
              type="button"
              disabled={!isEditorDisabled}
              onClick={openModal}
            >
              {isSending ? <Loading text="" /> : 'Send'}
            </FormButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(AdminSendNotificationPage)
