import { IProps } from './AlertTypeWaterLevel'
import SubscriptionItem from './SubscriptionItem'
import {
  NotificationTypeManualSitesMap,
  NotificationTypeMap,
} from '../../helper/constants'

const AlertTypeDefault: React.FC<IProps> = ({
  data,
  isActiveSubscription,
  updateState,
}) => {
  const { measurementType, name, isAutomated, description } = data

  return (
    <div className="marker-info-wrapper">
      <div className="text-wa">{name}</div>
      <div className="mt-2 leading-5 text-sm">
        {isAutomated ? measurementType : 'Community Notification'}
      </div>
      <div className="mt-2 leading-5 max-w-md text-center">
        {isAutomated ? '' : description}
      </div>
      <div
        className={`notif-type-wrapper mt-6  ${
          data.isAutomated ? 'justify-between' : 'justify-center'
        }`}
      >
        {Array.from(
          data.isAutomated
            ? NotificationTypeMap.entries()
            : NotificationTypeManualSitesMap.entries()
        ).map(([key, value]) => {
          return (
            <SubscriptionItem
              key={key}
              name={value}
              isActive={isActiveSubscription(key)}
              onClick={() => updateState(key)}
              className="w-18"
            />
          )
        })}
      </div>
    </div>
  )
}

export default AlertTypeDefault
